@import url(https://fonts.googleapis.com/css2?family=Fira+Sans:wght@400;500;600;700;800&display=swap);
body {
    margin: 0;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto',
        'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans',
        'Helvetica Neue', sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
        monospace;
}

@font-face {
    font-family: 'SF UI Display Regular';
    src: local('SF UI Display Regular'),
        url(/static/media/sf_ui_display_regular.2a83d664.ttf) format('truetype');
}

@font-face {
    font-family: 'SF UI Display Heavy';
    src: local('SF UI Display Heavy'),
        url(/static/media/SFUIDisplay-Heavy.b7c29ecb.ttf) format('truetype');
}

@font-face {
    font-family: 'SF UI Display Bold';
    src: local('SF UI Display Bold'),
        url(/static/media/SFUIDisplay-Bold.d5e06ca5.ttf) format('truetype');
}

@font-face {
    font-family: 'SF UI Display Semibold';
    src: local('SF UI Display Semibold'),
        url(/static/media/SFUIDisplay-Semibold.43c109ed.ttf) format('truetype');
}

@font-face {
    font-family: 'SF UI Display Medium';
    src: local('SF UI Display Medium'),
        url(/static/media/SFUIDisplay-Medium.0aec296a.ttf) format('truetype');
}

@font-face {
    font-family: 'Montserrat Medium';
    src: local('Montserrat Medium'),
        url(/static/media/Montserrat-Medium.e2d60bc4.ttf) format('truetype');
}

@font-face {
    font-family: 'Montserrat SemiBold';
    src: local('Montserrat SemiBold'),
        url(/static/media/Montserrat-SemiBold.fa8441f3.ttf) format('truetype');
}

@font-face {
    font-family: 'Montserrat Bold';
    src: local('Montserrat Bold'),
        url(/static/media/Montserrat-Bold.079ca05d.ttf) format('truetype');
}

@font-face {
    font-family: 'Montserrat ExtraBold';
    src: local('Montserrat ExtraBold'),
        url(/static/media/Montserrat-ExtraBold.1944c8d1.ttf) format('truetype');
}

/* The Modal (background) */
.modalWindow {
    position: fixed; /* Stay in place */
    z-index: 400; /* Sit on top */
    padding-top: 100px; /* Location of the box */
    left: 0;
    top: 0;
    width: 100vw; /* Full width */
    height: 100vh; /* Full height */
    overflow: auto; /* Enable scroll if needed */
    background-color: rgb(0, 0, 0); /* Fallback color */
    background-color: rgba(0, 0, 0, 0.4); /* Black w/ opacity */
}

/* Modal Content */
.modalWindow__content {
    position: relative;
    background-color: #e8e8e8;
    margin: auto;
    width: calc(726px - 80px);
    height: calc(474px - 80px);
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
    -webkit-animation-name: animatetop;
    -webkit-animation-duration: 0.4s;
    animation-name: animatetop;
    animation-duration: 0.4s;

    border-radius: 40px;
    padding: 40px;
}

/* Add Animation */

@keyframes animatetop {
    from {
        top: -300px;
        opacity: 0;
    }
    to {
        top: 0;
        opacity: 1;
    }
}

/* The Close Button */
.modalWindow__close_button {
    position: absolute;
    right: 35px;
    top: 35px;
}

.modalWindow__close_button:hover,
.modalWindow__close_button:focus {
    color: #404040;
    text-decoration: none;
    cursor: pointer;
}

.modalWindow__body {
    overflow: scroll;
    height: 100%;
    -ms-overflow-style: none; /* IE and Edge */
    scrollbar-width: none; /* Firefox */
}

/* Hide scrollbar for Chrome, Safari and Opera */
.modalWindow__body::-webkit-scrollbar {
    display: none;
}

@media screen and (max-width: 726px) {
    .modalWindow {
        padding-top: 40px;
    }

    .modalWindow__content {
        width: 90vw;
        max-width: 570px;
        min-height: 590px;

        padding: 20px;
    }

    .modalWindow__close_button {
        margin-right: 5px;
        margin-top: 5px;
    }
}

@media screen and (max-width: 480px) {
    .modalWindow__content {
        width: 80vw;
        max-width: 344px;

        min-height: 670px;
    }

    .modalWindow__close_button {
        right: 15px;
        top: 15px;
    }
}

body {
    background: #f7f7f7;
    font-size: 17px;
    margin: 0;
    border: none;
    scrollbar-width: none;
}

body::-webkit-scrollbar {
    display: none;
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

/* Firefox */
input[type='number'] {
    -moz-appearance: textfield;
}

.main-content {
    min-height: 100vh;
    display: grid;

    grid-template-columns: 1fr;
    grid-template-rows: minmax(80px, auto) 2fr minmax(282px, auto);
    grid-template-areas:
        'heaer'
        'main'
        'footer';
}

.widgetMode {
    background: #f5f4f8;

    display: block;
    overflow: hidden;
}

