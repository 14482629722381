body {
    background: #f7f7f7;
    font-size: 17px;
    margin: 0;
    border: none;
    scrollbar-width: none;
}

body::-webkit-scrollbar {
    display: none;
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

/* Firefox */
input[type='number'] {
    -moz-appearance: textfield;
}

.main-content {
    min-height: 100vh;
    display: grid;

    grid-template-columns: 1fr;
    grid-template-rows: minmax(80px, auto) 2fr minmax(282px, auto);
    grid-template-areas:
        'heaer'
        'main'
        'footer';
}

.widgetMode {
    background: #f5f4f8;

    display: block;
    overflow: hidden;
}
