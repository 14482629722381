/* The Modal (background) */
.modalWindow {
    position: fixed; /* Stay in place */
    z-index: 400; /* Sit on top */
    padding-top: 100px; /* Location of the box */
    left: 0;
    top: 0;
    width: 100vw; /* Full width */
    height: 100vh; /* Full height */
    overflow: auto; /* Enable scroll if needed */
    background-color: rgb(0, 0, 0); /* Fallback color */
    background-color: rgba(0, 0, 0, 0.4); /* Black w/ opacity */
}

/* Modal Content */
.modalWindow__content {
    position: relative;
    background-color: #e8e8e8;
    margin: auto;
    width: calc(726px - 80px);
    height: calc(474px - 80px);
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
    -webkit-animation-name: animatetop;
    -webkit-animation-duration: 0.4s;
    animation-name: animatetop;
    animation-duration: 0.4s;

    border-radius: 40px;
    padding: 40px;
}

/* Add Animation */
@-webkit-keyframes animatetop {
    from {
        top: -300px;
        opacity: 0;
    }
    to {
        top: 0;
        opacity: 1;
    }
}

@keyframes animatetop {
    from {
        top: -300px;
        opacity: 0;
    }
    to {
        top: 0;
        opacity: 1;
    }
}

/* The Close Button */
.modalWindow__close_button {
    position: absolute;
    right: 35px;
    top: 35px;
}

.modalWindow__close_button:hover,
.modalWindow__close_button:focus {
    color: #404040;
    text-decoration: none;
    cursor: pointer;
}

.modalWindow__body {
    overflow: scroll;
    height: 100%;
    -ms-overflow-style: none; /* IE and Edge */
    scrollbar-width: none; /* Firefox */
}

/* Hide scrollbar for Chrome, Safari and Opera */
.modalWindow__body::-webkit-scrollbar {
    display: none;
}

@media screen and (max-width: 726px) {
    .modalWindow {
        padding-top: 40px;
    }

    .modalWindow__content {
        width: 90vw;
        max-width: 570px;
        min-height: 590px;

        padding: 20px;
    }

    .modalWindow__close_button {
        margin-right: 5px;
        margin-top: 5px;
    }
}

@media screen and (max-width: 480px) {
    .modalWindow__content {
        width: 80vw;
        max-width: 344px;

        min-height: 670px;
    }

    .modalWindow__close_button {
        right: 15px;
        top: 15px;
    }
}
